<template>
  <el-container class="shopList ">
    <el-header class="box add">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>企业管理</el-breadcrumb-item>
        <el-breadcrumb-item>新增企业</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main class="p15 pt15">
      <el-form :inline="true" status-icon ref="form" :model="form" class="demo-ruleForm">
        <el-row class="box mt20  f14">
          <el-col :span="11" class="tl">
            <el-form-item label="监管类型：" size="medium" prop="company_type_id" class="w" label-width="28%"
              :rules="[{required: true,message: '请选择监管类型',trigger: 'blur'}]">
              <el-select v-model="form.company_type_id" filterable placeholder="请选择监管类型"
                @change="getTag(form.company_type_id)">
                <el-option v-for="item in typetOptions" :key="item.company_type_id" :label="item.company_type_name"
                  :value="String(item.company_type_id)"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item label="主体类型：" size="medium" prop="company_tag_id" class="w" label-width="28%"
              :rules="[{required: true,message: '请选择主体类型',trigger: 'blur'}]">
              <el-select v-model="form.company_tag_id" filterable placeholder="请选择主体类型">
                <el-option v-for="item in tagOptions" :key="item.company_tag_id" :label="item.company_tag_name"
                  :value="String(item.company_tag_id)"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
           <el-col :span="11" class="tl">
            <el-form-item class="w" label-width="28%" label="统一社会信用代码：" size="medium" prop="credit_code"  :rules="[{required: true, message: '请输入统一社会信用代码', trigger: 'blur' },{ min: 18,max: 18, message: '请如实填写18位统一社会信用代码，以供核对', trigger: 'blur' }]">
              <el-input placeholder="请输入统一社会信用代码"  clearable 
                v-model="form.credit_code" class="input-with-select">
                 <el-button slot="append"  @click="companyCheck">验证企业</el-button>
              </el-input>
            </el-form-item>
          </el-col>
           <!-- <el-col :span="11" class="tl" :offset="1">
            <el-form-item class="w" label-width="28%"  size="medium" >
             <el-button   class="ml_5 mb5"  size="medium" type="primary" >验证企业</el-button>
            </el-form-item>
          </el-col> -->
          <el-col v-if="form.company_tag_id!=1&&form.company_tag_id!=2&&form.company_tag_id!=4" :span="11" class="tl">
            <el-form-item label="企业类型：" size="medium" prop="company_type" class="w" label-width="28%">
              <el-select v-model="form.company_type" filterable placeholder="请选择企业类型">
                <el-option v-for="item in typeCompany" :key="item.dictionary_field" :label="item.dictionary_field"
                  :value="item.dictionary_field"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item label="企业名称：" class="w" label-width="28%" prop='company_name'
              :rules="[{required: true,message: '请输入企业名称', trigger: 'blur' },{ pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, message: '不允许输入空格等特殊符号' }]">
              <el-input v-model="form.company_name" size="medium" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22" class="tl">
            <el-row class="box  f14">
              <el-form-item class="w" label-width="14%" label="经营地址：" size="medium" prop="vill"
                :rules="[{required: true,message: '请选择到村级', trigger: ['blur','change']}]">
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select clearable v-model="provinceTemp" placeholder="请选择省级" @change="handleChange($event,2)"
                      value-key="region_name">
                      <el-option v-for="item in provinceOptions" :key="item.region_code" :label="item.region_name"
                        :value="item"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select clearable v-model="cityTemp" placeholder="请选择市级" @change="handleChange($event,3)"
                      value-key="region_name">
                      <el-option v-for="item in cityOptions" :key="item.region_code" :label="item.region_name"
                        :value="item"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select clearable v-model="areaTemp" placeholder="请选择区/县" @change="handleChange($event,4)"
                      value-key="region_name" >
                      <el-option v-for="item in areaOptions" :key="item.region_code" :label="item.region_name"
                        :value="item"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select clearable v-model="townTemp" placeholder="请选择街道/镇" @change="handleChange($event,5)"
                      value-key="region_name" >
                      <el-option v-for="item in townOptions" :key="item.region_code" :label="item.region_name"
                        :value="item"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select clearable v-model="villTemp" placeholder="请选择居委会/村" value-key="region_name"
                      @change="handleChange($event,6)">
                      <el-option v-for="item in villOptions" :key="item.region_code" :label="item.region_name"
                        :value="item"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-form-item>
            </el-row>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item label="详细地址：" class="w" label-width="28%">
              <el-input v-model="form.address" placeholder="如道路、门牌号、小区、楼栋号、单元室等（请勿重复录入省市区县信息）" size="medium" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item label="营业状态：" class="w" label-width="28%" size="medium" prop="business_state"
              :rules="[{required: true,message: '请选择营业状态',trigger: 'blur'}]">
              <el-select v-model="form.business_state" filterable placeholder="请选择营业状态">
                <el-option v-for="item in Options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="22" class="tl">
            <el-form-item label="企业门店照片：" class="w" label-width="14%">
              <div class="recordadd-upload">
                <el-upload v-if="imageUrl=='1234567'" ref="upload" multiple action="/pc/file/upload" accept="image/png,image/jpg,image/jpeg"
                  list-type="picture-card" :on-success="handlepoll" :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove" :before-remove="beforeRemove">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-upload v-else ref="upload" multiple action="" accept="image/png,image/jpg,image/jpeg"
                  list-type="picture-card" :http-request="(params) =>{return handlepoll(params)}"  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove" :before-remove="beforeRemove">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="22" class="tl">
            <el-button class="ml_5" size="medium" type="primary" @click="submitForm('form')">提交</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-main>
  </el-container>
</template>
<script>
import axios from '../../../util/api'
import { local, aa } from '../../../util/util'
import { tengxuncloud } from '../../../util/aliOss'
import { mapState } from 'vuex'
import { imageUrl } from '../../../config/index'
export default {
  data () {
    return {
      imageUrl:imageUrl,
      form: {
        company_type_id: '',
        company_tag_id: '',
        company_type: '',
        credit_code:'',
        province: "",
        city: "",
        area: "",
        town: "",
        vill: "",
        address: "",
        business_state: "1",
        company_logo_url: '',
        company_name: ''
      },
      user: '',
      typetOptions: [],
      tagOptions: [],
      typeCompany: [],
      provinceTemp: "",
      cityTemp: "",
      areaTemp: "",
      townTemp: "",
      villTemp: "",
      provinceOptions: [],
      cityOptions: [],
      areaOptions: [],
      townOptions: [],
      villOptions: [],
      userData: {},
      Options: [{ label: "正常营业", value: "1" },
      { label: "暂停营业", value: "2" },
      { label: "停止营业", value: "3" }],
      dialogImageUrl: '',
      dialogVisible: false,
      page: {
        pager_offset: '0',
        pager_openset: '10'
      },
      checkCompany:false,
    }
  },
  created () {
    var that = this;
    that.user = {};
    this.getType()
  },
  mounted () {
    this.userData ={};
    this.handleUserLevel(this.userData);

  },
  methods: {
    getType () {
      var that = this;
      axios.get('/pc/company-type/all').then((v) => {
        that.typetOptions = v.data.company_type_list;
      })
    },
    getTag (params) {
      var that = this;
      axios.get('/pc/company-tag/all', { company_type_id: params }).then((v) => {
        that.tagOptions = v.data.company_tag_list;
        that.form.company_tag_id = String(that.tagOptions[0].company_tag_id)
        that.getDictionary(that.form.company_tag_id)
      })
    },
    getDictionary (params) {
      var that = this;
      axios.get('/pc/dictionary/all', { company_tag_id: params }).then((v) => {
        that.typeCompany = v.data.dictionary_list.企业类型.企业类型;
      })
    },
    companyCheck(){
      if(this.form.credit_code==''){
        this.$message.error("请输入统一社会信用代码");
            return false;
      };
      axios.get('/pc/company/check', { credit_code: this.form.credit_code }).then((v) => {
        // if(v.code==200||v.code==369){
        //   this.checkCompany=true
        // }
        this.form.company_name=v.data.companyName;
        
      })
    },
    async handleUserLevel (userData) {
      // if (parseInt(userData.user_type) > 0) {
        this.provinceOptions = await this.fetchRegionData(1);
        let province = this.provinceOptions.find(
          item => item.region_name == userData.user_province
        );
        this.provinceTemp = province;
        if (parseInt(userData.user_type) >= 1 && userData.user_province != null && userData.user_province != '') {
          this.cityOptions = await this.fetchRegionData(2, province.region_code);
          let city = this.cityOptions.find(item => item.region_name == userData.user_city);
          if (parseInt(userData.user_type) >= 2 && userData.user_city != null && userData.user_city != '') {
            this.areaOptions = await this.fetchRegionData(3, city.region_code);
            let area = this.areaOptions.find(
              item => item.region_name == userData.user_area
            );
            this.cityTemp = city;
            if (parseInt(userData.user_type) >= 3 && userData.user_area != null) {
              this.townOptions = await this.fetchRegionData(4, area.region_code);
              let town = this.townOptions.find(
                item => item.region_name == userData.user_town
              );
              this.areaTemp = area;
              if (parseInt(userData.user_type) >= 4 && userData.user_town != null) {
                this.villOptions = await this.fetchRegionData(5, town.region_code);
                let vill = this.villOptions.find(
                  item => item.region_name == userData.user_vill
                );
                this.townTemp = town;
                if (parseInt(userData.user_type) >= 5 && userData.user_vill != null) {
                  this.villTemp = vill;
                }
              }
            }
          }
        }
      // }
    },
    fetchRegionData (type, region_high_code) {
      let region_type = type.toString();
      return new Promise((resolve, reject) => {
        axios
          .get("/pc/region/all", { region_type, region_high_code })
          .then(response => {
            resolve(response.data.region_list);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    async handleChange (item, type) {
      // let regionData = await this.fetchRegionData(type, item.region_code);
      let regionData;
      if (item.region_code == undefined) {
        regionData = ''
      } else {
        regionData = await this.fetchRegionData(type, item.region_code);
      }
      switch (type) {
        case 2:
          this.cityOptions = regionData;
          this.form.province = item.region_name;
          this.areaOptions = [];
          this.townOptions = [];
          this.villOptions = [];
          this.cityTemp = "";
          this.areaTemp = "";
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 3:
          this.areaOptions = regionData;
          this.form.city = item.region_name;
          this.townOptions = [];
          this.villOptions = [];
          this.areaTemp = "";
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 4:
          this.townOptions = regionData;
          this.form.area = item.region_name;
          this.villOptions = [];
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 5:
          this.villOptions = regionData;
          this.form.town = item.region_name;
          this.villTemp = "";
          break;
        case 6:
          this.form.vill = item.region_name;
          break;
      }
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handlepoll (response, file, fileList) {
      if(this.imageUrl=='1234567'){
        this.form.company_logo_url = response.data.visit_url;
      }else{
        tengxuncloud(response).then((res) => {
        this.form.company_logo_url =res.url
       })
      }
      
    },
    handleRemove (file, fileList) {
      this.form.company_logo_url = [];
      fileList.forEach(item1 => {
        this.form.company_logo_url = item1.response.data.visit_url;
      });
    },
    beforeRemove (file, fileList) {
      if (file && file.status==="success") {
      return this.$confirm(`确定移除门店照片吗？`);
      }
      
    },
    submitForm (form) {
      let params = {
        company_id: '',
        company_name: this.form.company_name
      }
      // if(this.checkCompany==false){
      //   this.$message.error("当前企业需要验证");
      //       return false;
      // }
      this.$refs[form].validate((valid) => {
        this.form.province = this.provinceTemp.region_name;
        this.form.city = this.cityTemp.region_name;
        this.form.area = this.areaTemp.region_name;
        this.form.town = this.townTemp.region_name;
        this.form.vill = this.villTemp.region_name;
        if (valid) {
          // axios.get("/pc/company/exist", params).then(v => {
            axios.post('/pc/company/save', this.form).then((v1) => {
              local.set('company_id', v1.data.company_id);
              local.set('company_name', this.form.company_name);
              local.set('isMenu', 2)
              axios.put('/pc/user-role/refresh/menu', { last_login_company_id: v1.data.company_id }).then((v2) => {
                this.menu_list = v2.data.menu_list;
                this.url = v2.data.menu_list[0].menu_list[0].menu_url;
                this.$router.replace({
                  path: this.url + '?company_id=' + v1.data.company_id
                });
              })
            })

          // });

        }else{
           this.$message.error('请检查表单必填项');
            return false
        }
      })
    },
    getCompany (params) {
      var that = this;
      axios.get('/pc/company/single', { company_id: String(params) }).then((v) => {
        local.set('company', v.data.company);
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    top: 0px;
    left: 30px;
    justify-content: space-between;
    align-items: center;
  }
  .active {
    background: #2ec68a;
    border-color: #2ec68a;
    color: #ffffff;
  }
  .list_tab {
    padding: 10px;
    background-color: #f5f7fa;
    .list_tag {
      right: -10px;
      padding: 2px 6px;
      border-radius: 10px;
      top: -10px;
    }
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
  /deep/.el-table .aa .cell {
    display: flex;
    justify-content: center;
  }
}
</style>
